<template>
  <div>
    <van-cell title="123" is-link>
      <!-- 使用 title 插槽来自定义标题 -->
      <template>
        <span class="custom-title">单元格</span>
        <van-tag type="danger">标签</van-tag>
      </template>
    </van-cell>
  </div>
</template>

<script>
import { List, PullRefresh, Tab, Tabs, Cell } from "vant";
import { cargoList } from "@/service/api";

const data = [
  "huoyh",
  "jiangnn",
  "tangwt",
  "xuss",
  "beib",
  "hect",
  "xiaox",
  "huoyh1",
  "jiangnn1",
  "tangwt1",
  "xuss1",
  "beib1",
  "hect1",
  "xiaox1",
];

export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
  },
  data() {
    return {
      cargos: [],
      loading: false,
      refreshing: false,
      active: 0,
      contentHeight: 0,
    };
  },
  mounted() {
    this.contentHeight = document.documentElement.clientHeight - 50 - 44 + "px";
  },
  methods: {
    onRefresh() {
      this.cargos = data;
      setTimeout(() => {
        this.refreshing = false;
      }, 1000);
    },
    onLoad() {
      this.page = this.page + 1;
      this.cargoList();
    },
    async cargoList() {
      const params = {
        currentPage: this.page,
        pageSize: 10,
        statusAry: "1,2,3,4,5",
        sorter: "id_desc",
      };

      const res = await cargoList(params);

      this.refreshing = false;
      this.loading = false;

      if (res.success) {
        const { data, total } = res;
        if (this.page === 1) this.cargos = [];
        this.cargos = this.cargos.concat(data);
      }
    },
  },
};
</script>
<style></style>